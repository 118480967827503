import React from "react"
import Wrapper from "../../components/wrapper";
import SectionTitle from "../../components/sectionTitle";

const Cloudwedding = ({location}) => {
    return (
        <Wrapper
            customTitle={`A unique first-of-its-kind online cloud wedding project`} 
            customDescription={"A unique first-of-its-kind online wedding of Khailee Ng and Elisa Khong not only propelled our skills, challenged our creativity, but also inspired our hearts."} 
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"} 
            customOgImage={"/cover-home.jpg"}
            slug={location.pathname}
            >
            <section className="bg-brand-dark-blue pt-36 pb-20 md:pb-24">
                <div className="container mx-auto">
                    <SectionTitle
                        wrapperClassName=""
                        alignCenter={true}
                        title="Cloud Wedding"
                        minititle="Project"
                        type="h1"
                        invert={true}
                        childrenClassName="lg:w-1/2"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                    >
                        <p>
                            A unique first-of-its-kind online wedding of Khailee Ng and Elisa Khong not only propelled our skills, challenged our creativity, but also inspired our hearts.
                        </p>
                        <p className={`mt-8 inline-block btn btn-light`}>
                            <a href="https://cloudwedding.co/" target="_blank" rel="noopener noreferrer">Visit Website</a>
                        </p>
                    </SectionTitle>
                </div>
            </section>
            <section className="py-16">
                <div className="container mx-auto">
                    <article className="md:w-2/3 mx-auto">
                        <h3 className="mb-10">Testimonial</h3>
                        <div className="flex flex-col space-y-5 text-base md:text-1.5xl leading-relaxed text-opacity-90">
                            <p className="quote">Appleseeds are not merely web developers, they are true partners in your ultimate outcome. Once you start working with them, they will show you gaps you don’t see, introduce opportunities that make a real difference. They are more capable than large companies, yet more agile than even smallest teams. They listen to you, weave into your workflow as though you’ve been friends for years. And, they are VERY technically competent.</p>
                            <p>I have built web products with developers from around the world since I was 15 years old. I am an expert in online news, having founded SAYS.com, acquired Oh! Bulan, built news properties to lead Malaysia’s online readership before listing the group as REV Asia Bhd. I know online news well. I know Appleseeds can do the job. I even trusted them with the most important project in my life &mdash; my wedding. I gave them an impossible mobile and web experience on an impossible timeline. The features they built were never-seen-before on the web.</p>
                            <p>My friends from over 40 countries who are in the tech industry, were amazed at their work! If you are looking to secure the right partners in outcomes, this is the team. If you want to go beyond ‘news’ and amaze readers, throw your most daring ideas at them. Invest in the outcomes you deserve. I am telling you, this is the team.</p>
                            <p className="text-brand-gold"><em>&mdash; <strong>Khailee Ng</strong>, founder of SAYS.com and Managing Partner of 500 Startups, investor in Grab, Fave, Kaodim, Carsome, Carousell, and 189 other leading Southeast Asian startups.</em></p>
                        </div>

                    </article>
                </div>
            </section>
        </Wrapper>
    )
}

export default Cloudwedding;